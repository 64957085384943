import React from "react";

export const useIntersection = (element: any, rootMargin: any, root?: any, threshold: any = 0) => {
    const [isVisible, setState] = React.useState(false);

    React.useEffect(() => {
        if (!element.current) return;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            }, {rootMargin, root: !!root ? root.current : null, threshold}
        );
        const currentElement = element.current;

        element.current && observer.observe(element.current);

        return () => {
            observer.unobserve(currentElement)
        };
    }, [root]);

    return isVisible;
};


export function useDocumentTitle(title: string, prevailOnUnmount = false, deps: any[] = []) {
    const defaultTitle = React.useRef(document.title);

    React.useEffect(() => {
        document.title = title;
    }, [title]);

    React.useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = defaultTitle.current;
        }
    }, deps);
}


export function useAsyncImport(factory: () => Promise<any>, componentName: string) {
    const [loadedImport, setLoadedImport] = React.useState<any>(null);

    factory().then(module => {
        setLoadedImport(() => module[componentName]);
    })

    return [loadedImport]
}

const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: true, subtree: true },
};

export function useMutationObservable(targetEl: HTMLElement | undefined, cb: any, options = DEFAULT_OPTIONS) {
  const [observer, setObserver] = React.useState<any | null>(null);

  React.useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [cb, options, setObserver]);

  React.useEffect(() => {
    if (!observer || !targetEl) return;
    const { config } = options;
    observer.observe(targetEl, config);
    // console.log("Rendering");
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, options]);
}