import {ReactComponent as AppLogo} from "../assets/perfectomy_logo.svg";


export const LoadingIndicator = () => {
    return (
        <div className="animated-logo">
            <AppLogo />
        </div>
    )
}

export default LoadingIndicator;