/// <reference types="vite-plugin-svgr/client" />
import React, {Suspense} from 'react';
import * as Sentry from "@sentry/react";
import {BrowserRouter as Router} from "react-router-dom";

import "./theme.less"
import "./App.scss";

import {ReactComponent as AppError} from "./assets/app_error.svg";
import {Typography} from "antd";
import {LoadingIndicator} from "./shared/LoadingIndicator";
import {useDocumentTitle} from "./shared/hooks.tsx";
import { registerLicense, validateLicense } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5cdnVWRWJYUkN3V0I=');
validateLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5cdnVWRWJYUkN3V0I=');

if (import.meta.env.PROD) {
    Sentry.init({
        dsn: "https://5feca9fa470f4d998e1dae4aa3d8e5eb@o1225812.ingest.sentry.io/6371492",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ['app.perfectomy.com', 'http://localhost:8000', '/api/'],
                traceXHR: true
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.05,
        ignoreErrors: ['ResizeObserver loop limit exceeded', 'ChunkLoadError', 'CKEditor'],
        release: import.meta.env.VITE_APP_VERSION
    });
}

const AppRouterLazy = React.lazy(() => import(/*webpackChunkName: "app-router" */'./AppRouter'));
const ProvideAuthLazy = React.lazy(() => import(/*webpackChunkName: "app-router" */'./auth/provider'));
const Result = React.lazy(() => import(/*webpackChunkName: "antd-result" */'antd/lib/result/index'));

function App() {
    useDocumentTitle('Perfectomy');

    return (
        <Suspense fallback={<LoadingIndicator/>}>
            <Sentry.ErrorBoundary fallback={(
                <Result
                    icon={(
                        <AppError style={{maxWidth: "512px", maxHeight: "512px", margin: "0 auto"}}/>
                    )}
                    title="Unexpected error occurred please reload the page."
                    subTitle="If it still does not work contact support."
                    extra={[
                        <Typography.Link key="home-link" href="https://app.perfectomy.com/">Go Home</Typography.Link>
                    ]}
                />
            )}>
                <ProvideAuthLazy>
                    <Router>
                        <AppRouterLazy/>
                    </Router>
                </ProvideAuthLazy>

            </Sentry.ErrorBoundary>
        </Suspense>
    );
}

export default App;
